import { default as index1UXHxKQ3jgMeta } from "/workspace/src/pages/checkout/[id]/index.vue?macro=true";
import { default as successFlQ4rH0UXaMeta } from "/workspace/src/pages/checkout/[id]/success.vue?macro=true";
import { default as contacts3zxuBjMpX6Meta } from "/workspace/src/pages/contacts.vue?macro=true";
import { default as indexY91ZNcMAe3Meta } from "/workspace/src/pages/home-filtration/index.vue?macro=true";
import { default as price3RAyeGHsXOMeta } from "/workspace/src/pages/home-filtration/price.vue?macro=true";
import { default as indexQ0gwGo0vtVMeta } from "/workspace/src/pages/index.vue?macro=true";
import { default as pricebF8a6sjVYWMeta } from "/workspace/src/pages/installation/price.vue?macro=true";
import { default as leak_45detectionO0hYOZuibaMeta } from "/workspace/src/pages/leak-detection.vue?macro=true";
import { default as privacy_45policyDWXb3mCeCdMeta } from "/workspace/src/pages/privacy-policy.vue?macro=true";
import { default as indexUGzkNMrjZXMeta } from "/workspace/src/pages/reverse-osmosis/index.vue?macro=true";
import { default as pricelWXJCqamkwMeta } from "/workspace/src/pages/reverse-osmosis/price.vue?macro=true";
export default [
  {
    name: index1UXHxKQ3jgMeta?.name ?? "checkout-id",
    path: index1UXHxKQ3jgMeta?.path ?? "/checkout/:id()",
    meta: index1UXHxKQ3jgMeta || {},
    alias: index1UXHxKQ3jgMeta?.alias || [],
    redirect: index1UXHxKQ3jgMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: successFlQ4rH0UXaMeta?.name ?? "checkout-id-success",
    path: successFlQ4rH0UXaMeta?.path ?? "/checkout/:id()/success",
    meta: successFlQ4rH0UXaMeta || {},
    alias: successFlQ4rH0UXaMeta?.alias || [],
    redirect: successFlQ4rH0UXaMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: contacts3zxuBjMpX6Meta?.name ?? "contacts",
    path: contacts3zxuBjMpX6Meta?.path ?? "/contacts",
    meta: contacts3zxuBjMpX6Meta || {},
    alias: contacts3zxuBjMpX6Meta?.alias || [],
    redirect: contacts3zxuBjMpX6Meta?.redirect || undefined,
    component: () => import("/workspace/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexY91ZNcMAe3Meta?.name ?? "home-filtration",
    path: indexY91ZNcMAe3Meta?.path ?? "/home-filtration",
    meta: indexY91ZNcMAe3Meta || {},
    alias: indexY91ZNcMAe3Meta?.alias || [],
    redirect: indexY91ZNcMAe3Meta?.redirect || undefined,
    component: () => import("/workspace/src/pages/home-filtration/index.vue").then(m => m.default || m)
  },
  {
    name: price3RAyeGHsXOMeta?.name ?? "home-filtration-price",
    path: price3RAyeGHsXOMeta?.path ?? "/home-filtration/price",
    meta: price3RAyeGHsXOMeta || {},
    alias: price3RAyeGHsXOMeta?.alias || [],
    redirect: price3RAyeGHsXOMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/home-filtration/price.vue").then(m => m.default || m)
  },
  {
    name: indexQ0gwGo0vtVMeta?.name ?? "index",
    path: indexQ0gwGo0vtVMeta?.path ?? "/",
    meta: indexQ0gwGo0vtVMeta || {},
    alias: indexQ0gwGo0vtVMeta?.alias || [],
    redirect: indexQ0gwGo0vtVMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pricebF8a6sjVYWMeta?.name ?? "installation-price",
    path: pricebF8a6sjVYWMeta?.path ?? "/installation/price",
    meta: pricebF8a6sjVYWMeta || {},
    alias: pricebF8a6sjVYWMeta?.alias || [],
    redirect: pricebF8a6sjVYWMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/installation/price.vue").then(m => m.default || m)
  },
  {
    name: leak_45detectionO0hYOZuibaMeta?.name ?? "leak-detection",
    path: leak_45detectionO0hYOZuibaMeta?.path ?? "/leak-detection",
    meta: leak_45detectionO0hYOZuibaMeta || {},
    alias: leak_45detectionO0hYOZuibaMeta?.alias || [],
    redirect: leak_45detectionO0hYOZuibaMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/leak-detection.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyDWXb3mCeCdMeta?.name ?? "privacy-policy",
    path: privacy_45policyDWXb3mCeCdMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyDWXb3mCeCdMeta || {},
    alias: privacy_45policyDWXb3mCeCdMeta?.alias || [],
    redirect: privacy_45policyDWXb3mCeCdMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexUGzkNMrjZXMeta?.name ?? "reverse-osmosis",
    path: indexUGzkNMrjZXMeta?.path ?? "/reverse-osmosis",
    meta: indexUGzkNMrjZXMeta || {},
    alias: indexUGzkNMrjZXMeta?.alias || [],
    redirect: indexUGzkNMrjZXMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/reverse-osmosis/index.vue").then(m => m.default || m)
  },
  {
    name: pricelWXJCqamkwMeta?.name ?? "reverse-osmosis-price",
    path: pricelWXJCqamkwMeta?.path ?? "/reverse-osmosis/price",
    meta: pricelWXJCqamkwMeta || {},
    alias: pricelWXJCqamkwMeta?.alias || [],
    redirect: pricelWXJCqamkwMeta?.redirect || undefined,
    component: () => import("/workspace/src/pages/reverse-osmosis/price.vue").then(m => m.default || m)
  }
]